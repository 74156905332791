import React from 'react'
import { useLocation } from 'react-router-dom';
import data from '../data.json'
import MarkdownIt from 'markdown-it';
import { Link } from 'react-router-dom';


export default function ImageDetail() {
    const params = useLocation();
    const imageUrl = params?.search?.split("?").filter(Boolean);
    const md = new MarkdownIt();

    return (
        <>
            <div className="content">

                <div className='add-analyse'>
                    <Link className='text-light text-decoration-none ' to="/"><button className='btn btn-primary mt-3 mx-3' style={{ float: "right" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-arrow-left pe-1" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8" />
                        </svg>Back</button>
                    </Link>
                </div>
                <div className='ps-3'> <h5><strong>X-ray Analysis Report</strong></h5></div>

                <div className='content-area'>
                    {data?.map((post, index) => (
                        post?.image == imageUrl ?
                            <>
                                <div className='ps-3  mt-5'>
                                    <img className='img-size' src={`/images/${post?.image}`} width={300} height={300} />
                                </div>
                                <div className="px-3 pt-5" key={index}>
                                    <p dangerouslySetInnerHTML={{
                                        __html: (post?.description)
                                    }} />
                                </div>
                            </>
                            : ""
                    ))}
                </div>
            </div>
        </>
    )
}
