import React from 'react'
import { Link } from 'react-router-dom'

export default function dashboard() {
    return (
        <>
            <div className='add-analyse'>
                {/* <h6>Sample X-ray Case Studies</h6> */}
                <Link className='text-light text-decoration-none ' to="/analyse"><button className='btn btn-primary mt-3 mx-3' style={{ float: "right" }}>
                     Analyse X-ray Image</button>
                </Link>
            </div>

            <div className='content-area'>
              <div className='ps-3'> <h5><strong>Sample X-ray Case Studies</strong></h5></div>
                <div className="px-3 pt-5">
                    <div className="image-grid ">
                        <div className="image-wrapper">
                            <img src="/images/image1.jpeg" alt="Image 1" />
                            <div className="overlay">
                                <Link to="/image_details?image1.jpeg">View</Link>
                            </div>
                        </div>
                        <div className="image-wrapper">
                            <img src="/images/image2.jpeg" alt="Image 2" />
                            <div className="overlay">
                                <Link to="/image_details?image2.jpeg">View</Link>
                            </div>
                        </div>

                        <div className="image-wrapper">
                            <img src="/images/image3.jpeg" alt="Image 3" />
                            <div className="overlay">
                                <Link to="/image_details?image3.jpeg">View</Link>
                            </div>
                        </div>

                        <div className="image-wrapper">
                            <img src="/images/image4.jpeg" alt="Image 4" />
                            <div className="overlay">
                                <Link to="/image_details?image4.jpeg">View</Link>
                            </div>
                        </div>

                        <div className="image-wrapper">
                            <img src="/images/image5.jpeg" alt="Image 5" />
                            <div className="overlay">
                                <Link to="/image_details?image5.jpeg">View</Link>
                            </div>
                        </div>

                        <div className="image-wrapper">
                            <img src="/images/image6.jpeg" alt="Image 6" />
                            <div className="overlay">
                                <Link to="/image_details?image6.jpeg">View</Link>
                            </div>
                        </div>
                       
                        {/* <!-- Repeat for other images --> */}
                    </div>
                </div>
            </div>
        </>
    )
}
