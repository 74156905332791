import React,{useEffect} from 'react'
import { useNavigate } from "react-router-dom";
 import { Link } from "react-router-dom";
export default function Header() {
    const navigate = useNavigate();

    const logout = () => {
        localStorage.setItem("loggedIn", "false");
        navigate("/login");
      }
    return (
        <>

            <header className="header-fixed">
                <div className="header-limiter">
                   <Link to="/"><h1><img src="/logo.svg" /></h1></Link> 
                    <nav>      
                        <a href="#" onClick={logout}>Logout</a>
                    </nav>
                </div>
            </header>

        </>
    )
}
