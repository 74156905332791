import React from 'react';
import { GoogleGenerativeAI } from "@google/generative-ai";
import { getBase64 } from '../imageHelper';
import { useState } from 'react';
import MarkdownIt from 'markdown-it';
 import { Link } from 'react-router-dom';


function AIwithImage() {
const [imageInlineData, setImageInlineData] = useState('');
const [image, setImage] = useState('');
const [response, setResponse] = useState('');
const [loading, setLoading] = useState(false);   

const genAI = new GoogleGenerativeAI('AIzaSyCpR4oZxfyj-8mFovxwKzlZ7byQxPmL6tg');
const model = genAI.getGenerativeModel({ model: "gemini-1.0-pro-vision-latest" });

const handleImageChange = (e) => {
    const file = e.target.files[0];
  
    // getting base64 from file to render in DOM
    getBase64(file)  
        .then((result) => {
            setImage(result);
        })
        .catch(e => console.log(e))
  
    // generating content model for Gemini Google AI
    fileToGenerativePart(file).then((image) => {
        setImageInlineData(image);
    });
    }
	
	const md = new MarkdownIt();
    
    const handleClick = () => {
        aiImageRun();
    }


    async function aiImageRun() {
        setLoading(true);
        setResponse('');        
        const  model = genAI.getGenerativeModel({ model: "gemini-1.5-flash" });
		const imageFindText = `
			Analyze the provided dental X-ray image to identify and highlight any signs of dental abnormalities. The analysis should focus on detecting the following conditions:

			1. Cavities (Dental Caries): 
			
			1.1. Identify areas with dark spots or shadows within the tooth structure that indicate decay. These areas typically appear as darker regions compared to the surrounding healthy enamel and dentin.

			1.2. Highlight any regions where the enamel shows signs of demineralization or erosion. This may appear as a loss of the normal translucency of the enamel.

			1.3. Look for irregularities or disruptions in the continuity of the tooth's surface that suggest the presence of decay or early-stage cavities.

			1.4. Pay attention to the proximity of the cavities to the pulp chamber, as deeper cavities closer to the pulp may indicate more advanced decay requiring immediate attention.

			1.5. Detect any interproximal caries, which are cavities that develop between the teeth, often visible as dark lines or spots in the spaces between adjacent teeth.

			1.6. Mark the boundaries of the detected cavities clearly on the image to distinguish them from healthy tooth structure.


			2. Gum Disease (Periodontal Issues): 
			2.1. Gum Recession:
			   - Identify areas where the gums appear to have receded or pulled away from the teeth.
			   - Look for exposed tooth roots, which may appear as longer tooth structures without the normal gum covering.
			   - Highlight any uneven gum lines or gaps between the gum and the teeth that suggest recession.

			2.2. Bone Loss:
			   - Examine the areas around the teeth for signs of bone loss, which may appear as a reduction in the bone height around the tooth roots.
			   - Compare the levels of the alveolar bone to the normal bone height expected around the teeth.
			   - Detect any visible reduction in the density or continuity of the bone that supports the teeth.

			2.3. Periodontal Pockets:
			   - Identify any deep pockets or gaps between the teeth and the gums, which may appear as dark or shadowy spaces.
			   - Look for irregularities in the attachment of the gums to the teeth that could indicate deep periodontal pockets.

			2.4. Tooth Mobility:
			   - Spot any signs that suggest teeth may be loose or shifting position due to the weakening of the supporting bone and tissues.
			   - Highlight any unusual spacing or alignment issues that could be a result of tooth mobility caused by periodontal disease.

			2.5. Inflammation and Infection:
			   - Detect any signs of inflammation or infection in the periodontal tissues, which may appear as increased soft tissue density or swelling around the tooth roots.
			   - Pay attention to any unusual patterns or density changes in the soft tissues around the teeth.
						
						
			3. Oral Cancer: Detect any irregular masses, lesions, or abnormal tissue formations within the oral cavity. Pay attention to unusual patterns or growths in the soft tissues and the jawbone areas.
			3.1. Masses and Irregular Lesions:
			   - Identify any irregular masses or lesions within the oral cavity that appear as abnormal tissue formations.
			   - Look for areas of increased density or abnormal shadows that do not correspond to normal anatomical structures.

			3.2. Soft Tissue Abnormalities:
			   - Detect any unusual thickening, swelling, or changes in texture of the soft tissues within the oral cavity.
			   - Pay attention to areas where the mucosal lining appears uneven or where there are visible abnormalities in tissue density.

			3.3. Bone Destruction:
			   - Examine the jawbone and surrounding structures for signs of bone destruction or erosion that may indicate advanced oral cancer.
			   - Look for areas where the bone appears irregular or where there are visible gaps or loss of normal bone density.

			3.4. Radiolucent Areas:
			   - Highlight any areas on the X-ray where there are radiolucent (dark) regions within the bone or soft tissues, which may indicate tumor growth or cystic formations.

			3.5. Calcifications and Tissue Changes:
			   - Identify any calcifications or changes in the texture of the tissues that suggest abnormal growth patterns or mineral deposits associated with oral tumors.
			   - Look for areas where there are irregular patterns or densities that do not conform to typical oral structures.

			3.6. Lymph Node Enlargement:
			   - Detect any signs of enlarged lymph nodes within the neck region, which may appear as increased soft tissue density or visible swelling on the X-ray.
			   - Highlight any abnormal lymph node formations that could indicate metastasis or spread of oral cancer.

			4. Tooth Fractures and Cracks: Identify any visible lines or separations that indicate fractures in the teeth. Highlight both major and minor cracks that may not be immediately visible.
			
			4.1. Major Tooth Fractures:
			   - Identify any clear, visible separations or breaks in the tooth structure that extend across significant portions of the tooth.
			   - Look for fractures that extend vertically from the chewing surface towards the root or horizontally across the tooth.
			   - Highlight any fractures that involve multiple parts of the tooth, such as the crown and the root.

			4.2. Minor Cracks and Hairline Fractures:
			   - Detect subtle, thin lines that may indicate minor or hairline cracks in the enamel or dentin.
			   - Pay attention to cracks that may not be immediately visible but show up as faint lines on the X-ray.
			   - Identify any small, localized cracks that do not extend across the entire tooth but could indicate stress or beginning stages of a fracture.

			4.3. Root Fractures:
			   - Examine the root areas for signs of fractures, which may appear as horizontal or vertical lines running through the root structure.
			   - Look for fractures that extend into the root, which can be more challenging to detect but are crucial for assessing the overall tooth health.

			4.4. Cusp Fractures:
			   - Spot any fractures that involve the cusps (pointed parts) of the teeth, which may appear as breaks or chips off the top parts of the tooth.
			   - Identify any displaced or separated cusps that could indicate a fracture that affects the tooth’s structural integrity.

			4.5. Split Teeth:
			   - Detect any signs of teeth that are split into two or more distinct segments. These splits often extend from the crown to the root and can be identified by clear separations in the tooth structure.
			   - Highlight both complete and incomplete splits, focusing on any areas where the tooth appears divided but still partially connected.

			4.6. Fractured Restoration:
			   - Identify any fractures or cracks in dental restorations (such as fillings, crowns, or veneers) that might compromise their integrity.
			   - Look for separations or breaks around the edges of restorations that suggest they are not fully intact.

			4.7. Multiple Cracks and Fractures:
			   - Highlight any areas with multiple cracks or fractures, particularly where a tooth may exhibit a combination of minor and major cracks.
			   - Assess the overall condition of the tooth to determine if multiple fractures suggest a compromised or weakened structure.

			Instructions:
			- Generate clear annotations or markings on the image to highlight the detected abnormalities.
			- Provide a detailed report minimum 2000 words only english language summarizing each identified condition with possible severity and recommendations for further examination or treatment.
			- Response return like descrption not bullet point wise like 1.1 or etc.
		  `;
		
		const updateText	=	``;

        const result = await model.generateContent([imageFindText, imageInlineData
        ]);
        const response = await result.response;
        const text = response.text();
        setResponse(text);
        setLoading(false);
    }

    async function fileToGenerativePart(file) {
        const base64EncodedDataPromise = new Promise((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result.split(',')[1]);
            reader.readAsDataURL(file);
        });
    
        return {
            inlineData: { data: await base64EncodedDataPromise, mimeType: file.type },
        };
    }


  return (
    <div className="mt-4 px-4">
      <div style={{ display: 'flex' }}>
          <input type='file' onChange={(e) => handleImageChange(e)} />
          <button style={{ marginLeft: '20px' }} onClick={() => handleClick()}>Analyse</button>
          <Link className='text-light text-decoration-none back-button' to="/"><button className='btn btn-primary mt-3 mx-3' style={{ float: "right" }}><svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-arrow-left pe-1" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8" />
                </svg>
                    Back</button>
                </Link>
      </div>
          <img src={image} style={{ width: '30%', marginTop: 30 }} />
          {
          loading == true && (response == '') ?
              <p style={{ margin: '30px 0' }}>Loading ...</p>
              :
              <div style={{ margin: '30px 0' }}>
                  <p dangerouslySetInnerHTML={{ __html: md.render(response) }} />
              </div>
        }
  </div>
  );
}

export default AIwithImage;