import React,{useEffect} from 'react'
import Header from './header/header'
import { useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

export default function Layout({children}) {
  let location = useLocation();

  const navigate = useNavigate();
  useEffect(() => {
    const loggedIn = localStorage.getItem("loggedIn");
    if (loggedIn === "false" && location?.pathname === "/" ) {
           navigate("/login")
        }

  }, []);
  // console.log(location)
  return (
   <>
    {location?.pathname === "/login"? "":<Header/>}
    {children}
   </>
  )
}
