const Constant=Object.freeze({
    IMAGE_URL				    	: '/image/', 
    EMAIL_REGEX                     : /^\s*[^\s@]+@[^\s@]+\.[^\s@]+\s*$/,
    EMAIL_REGEX_MESSAGE             : "Please enter valid email.",
    EMAIL_REQUIRED                  : "Email is required.",
    PASSWORD_REQUIRED               : "Password is required.",
    CONFIRMED_PASSWORD_REQUIRED     : "Confirm password is required.", 
    PASSWORD_MATCH                  : "Confirm password does not match.",
     SUCCESS_STATUS					: 'success',
	ERROR_STATUS					: 'error',
});  
export default Constant; 