
import React, { Suspense, useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import Layout from './components/layout';
import Dashboard from './components/dashboard/dashboard';
import Login from './pages/login';
import './assets/css/custom.css'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import { ToastContainer } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import ImageDetails from './pages/imageDetail';
import Analyse from './pages/analyse';

function App() {

  const [login, setLogin] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const loggedIn = localStorage.getItem("loggedIn");
    if (loggedIn) {
      setLogin(true);

        } else {

          localStorage.setItem("loggedIn", "false");
           setLogin(false);
           navigate("/login")
        }

  }, []);

   

  
  return (
    <>
    <ToastContainer/>
    <Layout>
    <Routes>
 
     <Route exact path="/login" element={<Login />} />
     <Route exact path="/"  element={<Dashboard />} />
     <Route exact path="/image_details"  element={<ImageDetails />} />
     <Route exact path="/analyse"  element={<Analyse />} />
  </Routes>
   </Layout>
   </>
  );
}

export default App;
