import React from 'react'
import { Link } from 'react-router-dom'
import { useForm } from "react-hook-form";
import Constant from '../config/constant'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function Login() {

    const { register, handleSubmit, watch, setError, formState: { errors } } = useForm();
    const navigate = useNavigate();

    const onLogin = async (formData) => {
        if(formData.email === "admin@gmail.com" && formData.password === "123456"){
          localStorage.setItem("loggedIn", "true");
      navigate("/")
        //   toast.success("You are successfully logged in")
        }else{
          setError("password", {
            type: "manual",
            message: "Username and password do not match!",
          })
        }
        
      }

    return (
        <>
            <div className="container login-content">
                <div className="card">
                    <h2>Login</h2>
                    <form onSubmit={handleSubmit(onLogin)}>
                        <input {...register("email", { required: Constant.EMAIL_REQUIRED, pattern: { value: Constant.EMAIL_REGEX, message: Constant.EMAIL_REGEX_MESSAGE } })} type="text" id="username"  placeholder="Username"  />
                        {errors?.email && (<small className='text-danger'>{errors?.email?.message}</small>)}
                        <input  {...register("password", { required: 'Please enter password.' })}  className="mt-3 " type="password" id="password"  placeholder="Password"  />
                        {errors?.password && (<small className='text-danger'>{errors?.password?.message}</small>)}
                        <button className="mt-3" type="submit">Login</button>
                    </form>
                </div>
            </div>

        </>
    )
}
